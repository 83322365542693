import { FC, memo } from 'react'
import { GenericComponentProps, GenericStandingsModel } from '@sport1/types/web'
import { SeasonClientModels } from '@sport1/types/sportData'
import PlaceHolderBox from '@/components/PlaceHolderBox'
import StandingsWithDropdown from '@/components/StandingsWithDropdown'
import useFetch from '@/hooks/useFetch'

type Props = Pick<
    GenericStandingsModel & GenericComponentProps,
    'seasonUrl' | 'contentUrl' | 'componentKey'
>

const StandingsGeneric: FC<Props> = ({ seasonUrl, contentUrl, componentKey }) => {
    const { data, loading, error } = useFetch<SeasonClientModels>(seasonUrl)

    if (loading || (!error && !data) || !data?.seasons?.length) {
        return <PlaceHolderBox height={800} />
    }

    return (
        <StandingsWithDropdown
            dropdownLabel="Saison"
            dropdownOptions={{
                firstValues: data.seasons.map(s => ({
                    label: s.name ?? '',
                    value: s.id ?? '',
                })),
                secondValues: [],
            }}
            testID={`${componentKey}-standings-generic`}
            contentUrl={contentUrl}
            originalDropdownData={data.seasons}
        />
    )
}

export default memo(StandingsGeneric)
